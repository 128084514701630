import React from 'react'
import Layout from '../layouts'
import weddingBcg from '../assets/wedding-background.jpg'
import loadable from '@loadable/component'
import Paragraph from '../components/paragraph/paragraph'

import { headerParagraph, weddingFaq, weddingInsuranceTiles, fullParagraphContent, Cover, tableInformations, paragraphImage, faqInformations, weddingHeader, checkTheme } from '../utils/informations'
import WeddingTiles from '../components/wedding-tiles/wedding-tiles'
import Faq from '../components/faq-v2/faq'
import FullScreenParagraph from '../components/fullscreen-paragraph/fullscreen-paragraph'
import CoveredList from '../components/coveredList/coveredList'

import ParagraphImage from '../components/paragraph-image/paragraph-image'
const SecondaryPagesHeader = loadable(() => import('../components/secondary-pages-header/secondary-pages-header'))
const ProductTable = loadable(() => import("../components/product-table/product-table"))


const WeddingInsurance = () => {
  return (

    <Layout pageTitle={`Wedding Insurance - ${checkTheme().pageTitle}`}>
      <SecondaryPagesHeader title={`Wedding Insurance`} background={weddingBcg} description={weddingHeader.description} checks={weddingHeader.checks} price={50} imgwebp={weddingHeader.imgwebp} imgpng={weddingHeader.imgpng} origin="Wedding" />
      


        <ParagraphImage props={paragraphImage} origin="Wedding" />
        <ProductTable props={tableInformations}/>
        <FullScreenParagraph props={fullParagraphContent} origin="Wedding"/>
        <CoveredList props={Cover} origin="Wedding"/>
        <Faq props={faqInformations} origin="Wedding"/>
      

    </Layout>

  )
}

export default WeddingInsurance